<template>
  <div style="padding:16px">
    <!-- 头部部分 -->
  <el-form :inline="true" >
    <el-form-item label="标题:">
      <el-input style="width:200px" v-model="queryInfo.condition.topic" label="请输入标题"></el-input>
    </el-form-item>
        <el-form-item label="版本编号:">
      <el-input style="width:200px"  v-model.number="queryInfo.condition.versionCode" label="请输入编号"></el-input>
    </el-form-item>
        <el-form-item label="版本名称:">
      <el-input style="width:200px" v-model="queryInfo.condition.versionName" label="请输入标题"></el-input>
    </el-form-item>
    <el-form-item label="渠道:">
      <el-select style="width:200px" v-model="queryInfo.condition.source" label="请选择渠道">
        <el-option label="IOS" :value="1"></el-option>
        <el-option label="安卓" :value="2"></el-option>
      </el-select>
    </el-form-item>
     <el-button  style="transform: translateY(2px);" @click="getList" type="primary">查询</el-button>
     <el-button  style="transform: translateY(2px);" @click="resetForm" >重置</el-button>
     <el-button  style="transform: translateY(2px);" type="primary" @click="newBtn">新建</el-button>

  </el-form>
    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData"
       tooltip-effect="dark" style="width: 100%" height="calc(100vh - 250px)">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
          <!-- <el-table-column label="升级编号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.recordCode }}</template>
          </el-table-column> -->
          <el-table-column label="升级标题" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.topic }}</template>
          </el-table-column>
          <el-table-column label="背景图" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image v-show="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]" :src="scope.row.imgUrl"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="渠道" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.source==1">IOS</span>
              <span v-if="scope.row.source==2">安卓</span>
       </template>
          </el-table-column>
          <el-table-column label="版本号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.versionCode }}</template>
          </el-table-column>
          <el-table-column label="版本名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.versionName }}</template>
          </el-table-column>
          <el-table-column label="是否开启提示" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.isRemind==0 ? '未开启' :'已开启' }}</template>
          </el-table-column>
          <el-table-column label="是否强制升级" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.isForceUpdate==1">是</span>
              <span v-if="scope.row.isForceUpdate==0">否</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createDate }}</template>
          </el-table-column>

          <el-table-column label="创建人名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createName }}</template>
          </el-table-column>
          <el-table-column label="修改时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateDate }}</template>
          </el-table-column>
          <el-table-column label="修改人名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateName }}</template>
          </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.recordCode)">
              <el-button style="color:red" slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog  v-dialogDrag
    :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item required label="升级标题：" >
                <el-input v-model="form.topic" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
                 <el-col :span="12">
              <el-form-item required label="渠道：">
                <el-select v-model="form.source" placeholder="请选择来源">
                  <el-option label="IOS" :value="1"></el-option>
                  <el-option label="安卓" :value="2"></el-option>
                </el-select>
                <!-- <el-input v-model="form.source" placeholder="请输入来源"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item required label="版本编号：">
                <el-input-number controls-position="right" v-model.number="form.versionCode" placeholder="请输入版本编号"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item required label="版本名称：">
                <el-input v-model="form.versionName" placeholder="请输入版本名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否开启提示" >
                  <el-radio-group v-model="form.isRemind">
      <el-radio-button label="1" >是</el-radio-button>
      <el-radio-button label="0" >否</el-radio-button>
           </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否强制升级：" >
                 <el-radio-group v-model="form.isForceUpdate">
      <el-radio-button label="1" >是</el-radio-button>
      <el-radio-button label="0" >否</el-radio-button>
           </el-radio-group>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item required label="升级内容：" >
                <div >
                  <div style=" display: flex;
    justify-content: space-between;
    align-items: center;margin-bottom:6px"
                  v-for="item,index in form.updateRecordDetailList" :key="index">
          <el-input style="width:220px" v-model="item.content" placeholder="请输入内容"></el-input>
        <i @click="addContent" v-show="index==0"
         style="font-size: 19px;cursor: pointer;" class="el-icon-circle-plus-outline"></i>
                 <i @click="removeContent(index)" v-show="index!=0"
         style="font-size: 19px;cursor: pointer;" class="el-icon-remove-outline"></i>
                  </div>

                </div>

              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item required label="背景图：" >
               <el-upload
  class="avatar-uploader"
  :action="$uploadURL"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload">
  <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import drag from '../../components/drag';
export default {
  data () {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          source:"",
          topic:'',
          versionCode:"",
          versionName:''
        }
      },
      form: {
        updateRecordDetailList:[{content:""}],
        topic:"",
        imgUrl:'https://api.zhongliangxny.com/resources/upload/file_20240124143839191444726507780.png',
        source: 1,
        versionCode:"",
        versionName:"",
         isRemind:"1",
       isForceUpdate:"1",
      },
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0
    };
  },

  created () {
    this.getList()
  },

  mounted () {
  },

  methods: {
        handleAvatarSuccess(res, file) {
        this.form.imgUrl = res.data.url
      },
      beforeAvatarUpload(file) {
        const isImg = file.type.slice(0,5) === 'image';
        if (!isImg) {
          this.$message.error('请上传图片');
        }
        return isImg
      },
      resetForm(){
        this.queryInfo.condition={
            source:"",
          topic:'',
          versionCode:"",
          versionName:''
        }
        this.getList()
      },
    //获取数据
    getList () {
	  var that = this;
	  that.$http.post("engineeringSystemUpdateRecord/list", that.queryInfo).then(function (response) {
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
   async handleSave () {
    let data=JSON.parse(JSON.stringify(this.form))
    if(this.labelType=='add'){
      data.createCode=JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode
      data.createName=JSON.parse(localStorage.getItem('userInfo')).userInfo.name
      delete data.id
    }else{
      data.updateCode=JSON.parse(localStorage.getItem('userInfo')).userCode
      data.updateName=JSON.parse(localStorage.getItem('userInfo')).name
    }
    let res=await this.$http.post("engineeringSystemUpdateRecord/saveUpdateRecord", data)
		  if (res.data.code == 200) {
			  this.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 this.showDialog = false
			  this.getList()
		  }else{
			  this.$notify.info({
				title: "提示",
				message: res.data.message,
				showClose: true,
			  });
		  }
    },
    addContent(){
      console.log(this.form.updateRecordDetailList.length)
      if(this.form.updateRecordDetailList[this.form.updateRecordDetailList.length - 1].content.trim()==''){
        this.$message.info('请先填写内容再添加')
        return
      }
      this.form.updateRecordDetailList.push({content:''})
    },
    removeContent(index){
      this.form.updateRecordDetailList.splice(index,1)
    },
    //修改
  async  edit (row) {
      this.showDialog = true;
       this.labelType = 'edit'
       this.form = JSON.parse(JSON.stringify(row))
      let res=await this.$http.post('engineeringSystemUpdateRecordDetail/queryList',{recordCode:row.recordCode})
    if(res.data.code==200){
      let arr=[]
      res.data.data.forEach(item=>{
        arr.push({content:item.content})
      })
      this.form.updateRecordDetailList=arr
    }


    },

	// 删除用户
    async handleDelte(recordCode) {
      let res=await   this.$http.post("engineeringSystemUpdateRecord/deleteByRecordCode" ,{recordCode})
			if(res.data.code == 200){
			 this.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  this.getList();
			}else{
				 this.$notify.info({
					title: "错误",
					message: res.data.message,
					showClose: true,
				  });
      }

    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {
        updateRecordDetailList:[{content:""}],
          topic:"",
        imgUrl:'https://api.zhongliangxny.com/resources/upload/file_20240124143839191444726507780.png',
        source: 1,
        versionCode:"",
        versionName:"",
         isRemind:"1",
       isForceUpdate:"1",
      }
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>

<style lang="scss" scoped>
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
./systemUpdateRecord.vue
